<template>
    <v-sheet class="vertical-table-row d-flex flex-column" style="flex: 1 0 auto">
        <v-row no-gutters align="center" class="txt txt--sm" :justify="justifySpaceBetween ? 'space-between' : undefined">
            <v-col cols="12" md="auto" v-bind="$attrs.headerProps" class="vertical-table-row__term">
                <div :class="termSize" class="txt--dark font-weight-medium pt-12px pb-4px px-md-8px py-md-8px">{{ term }} <span v-if="required" class="red--text">•</span></div>
            </v-col>
            <v-col cols="12" :md="justifySpaceBetween ? 'auto' : ''" style="white-space: pre-line" class="vertical-table-row__content">
                <div class="pt-4px pb-12px px-md-8px py-md-8px">
                    <slot />
                </div>
            </v-col>
        </v-row>
        <v-divider v-if="!noDivider" class="d-none d-md-block" />
    </v-sheet>
</template>

<script>
export default {
    props: {
        term: String,
        termSize: { type: String, default: "w-md-140px" },
        required: Boolean,
        noDivider: { type: Boolean, default: false },
        justifySpaceBetween: Boolean,
    },
};
</script>

<style lang="scss" scoped></style>
