var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "vertical-table-row d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": _vm.justifySpaceBetween ? 'space-between' : undefined
    }
  }, [_c('v-col', _vm._b({
    staticClass: "vertical-table-row__term",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, 'v-col', _vm.$attrs.headerProps, false), [_c('div', {
    staticClass: "txt--dark font-weight-medium pt-12px pb-4px px-md-8px py-md-8px",
    class: _vm.termSize
  }, [_vm._v(_vm._s(_vm.term) + " "), _vm.required ? _c('span', {
    staticClass: "red--text"
  }, [_vm._v("•")]) : _vm._e()])]), _c('v-col', {
    staticClass: "vertical-table-row__content",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "cols": "12",
      "md": _vm.justifySpaceBetween ? 'auto' : ''
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-12px px-md-8px py-md-8px"
  }, [_vm._t("default")], 2)])], 1), !_vm.noDivider ? _c('v-divider', {
    staticClass: "d-none d-md-block"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }